import React, { useState } from 'react'
import './CSS/DataAnalytics.css'
import da_cs_img_1 from './Assets/da_cs_img_1.png'
import process_optimization from './Assets/process_optimization.png'
import cost_reduction from './Assets/cost_reduction.png'
import performance_metrics from './Assets/performance_metrics.png'
import change_management from './Assets/change_management.png'
import da_cs_img_2 from './Assets/da_cs_img_2.png'
import customer_journey_mapping from './Assets/customer_journey_mapping.png'
import marketing_automation from './Assets/marketing_automation.png'
import personalization_strategies from './Assets/personalization_strategies.png'
import customer_retention from './Assets/customer_retention.png'
import da_cs_img_5 from './Assets/da_cs_img_5.png'
import it_modernization from './Assets/it_modernization.png'
import security_enhancements from './Assets/security_enhancements.png'
import performance_optimization from './Assets/performance_optimization.png'
import technology_alignment from './Assets/technology_alignment.png'
import da_cs_img_4 from './Assets/da_cs_img_4.png'
import digital_strategy_development from './Assets/digital_strategy_development.png'
import cloud_integration from './Assets/cloud_integration.png'
import data_driven_insights from './Assets/data_driven_insights.png'
import automation_solutions from './Assets/automation_solutions.png'
import talent_acquisition from './Assets/talent_acquisition.png'
import workforce_planning from './Assets/workforce_planning.png'
import employee_engagement from './Assets/employee_engagement.png'
import leadership_development from './Assets/leadership_development.png'
import da_cs_img_7 from './Assets/da_cs_img_7.png'
import regulatory_compliance from './Assets/regulatory_compliance.png'
import operational_risk_management from './Assets/operational_risk_management.png'
import business_continuity_planning from './Assets/business_continuity_planning.png'
import cyber_risk_mitigation from './Assets/cyber_risk_mitigation.png'
import da_cs_img_8 from './Assets/da_cs_img_8.png'
import it_strategy_development from './Assets/it_strategy_development.png'
import governance_compliance from './Assets/governance_compliance.png'
import vendor_management from './Assets/vendor_management.png'
import it_performance_monitoring from './Assets/it_performance_monitoring.png'
import data_analysis_1 from './Assets/data_analysis_1.png'
import trend from './Assets/trend.png'
import da_cs_img_3 from './Assets/da_cs_img_3.png'
import actionable_insight from './Assets/actionable_insight.png'
import strategy_development from './Assets/strategy_development.png'
import continuous_improvement from './Assets/continuous_improvement.png'
import logo from './Assets/logo.png'
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'





const DataAnalytics = () => {


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:4000/submitForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            console.log(data);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                message: ''
            });
            setIsSubmitted(true);
            closeModal();
            setTimeout(() => {
                setIsSubmitted(false);
            }, 5000);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };




    const handleMenuItemClick = (menuItem) => {
        // You can add any additional logic here if needed
    };



    return (
        <div className='da-cs'>
            <div className="da-cs-title-1">
                <h2>Consulting Services</h2>
                <p>At PCL Infotech, our consulting services are tailored to help you optimize your business operations, harness the power of technology, and make informed decisions that drive growth. Our team of experts provides strategic insights and practical solutions to propel your business forward.
                </p>
                <div className="da-cs-button-1">
                    <button onClick={openModal}>Get Quote Now</button>
                    <button>Learn More</button>
                </div>
                <h3>Industry</h3>
                <p>The consulting services industry provides expert advice and strategic guidance to businesses across various sectors to help improve their performance, efficiency, and profitability. Consulting firms typically offer specialized knowledge in areas such as management, finance, operations, IT, human resources, and marketing. Their services range from solving specific business problems to assisting with large-scale organizational transformations.</p>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
                <div className="web-contact-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>First Name</label>
                            <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                            <label>Last Name</label>
                            <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                            <label>Phone Number</label>
                            <input type="text" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea name="message" placeholder="Write your message.." value={formData.message} onChange={handleChange} required></textarea>
                        </div>
                        <button type="submit">Send Message</button>
                        {isSubmitted && <p>Thank you! Your form has been submitted.</p>}
                    </form>
                </div>
            </Modal>
            <div className="da-cs-subtitle-1">
                <h2>Management Consulting Services</h2>
                <p>In today’s rapidly evolving business landscape, organizations face a multitude of challenges, from operational inefficiencies and market disruptions to navigating complex regulatory environments. Our Management Consulting Services provide strategic insights and actionable solutions tailored to your business needs, enabling you to achieve sustainable growth and competitive advantage.</p>
            </div>
            <div className="da-cs-ctn-1">
                <div className="da-cs-ctn-title-2">
                    <h3>Business Operations</h3>
                    <p>Streamline and enhance your business operations with our expert consulting services. We work closely with you to analyze your current processes, identify inefficiencies, and develop customized strategies to boost productivity and reduce costs.
                        Our experts work closely with you to understand your business challenges and develop solutions that deliver measurable results.
                    </p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-1">
                    <img src={da_cs_img_1} alt="" />
                </div>
            </div>
            <div className="da-cs-card-1">
                <div className="da-cs-subcard-1">
                    <div className="process-optimization">
                        <img src={process_optimization} alt="" />
                        <h3>Process Optimization</h3>
                        <p>We assess your workflows to identify bottlenecks and recommend improvements that streamline operations
                        </p>
                    </div>
                    <div className="cost-reduction">
                        <img src={cost_reduction} alt="" />
                        <h3>Cost Reduction</h3>
                        <p>Our experts help you implement cost-saving measures without compromising on quality or performance
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-2">
                    <div className="performance-metrics">
                        <img src={performance_metrics} alt="" />
                        <h3>Performance Metrics</h3>
                        <p>We establish key performance indicators (KPIs) to monitor progress and measure the success of implemented strategies
                        </p>
                    </div>
                    <div className="change-management">
                        <img src={change_management} alt="" />
                        <h3>Change Management</h3>
                        <p>We support you in managing organizational changes to ensure smooth transitions and successful adoption of new processes.
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-2">
                <div className="da-cs-ctn-title-3">
                    <h3>Customer & Marketing</h3>
                    <p>Enhance your customer experience and marketing efforts with our consulting services. We provide insights and strategies that help you attract, engage, and retain customers. From customer journey mapping to marketing automation, we help you create a seamless and personalized experience that drives loyalty and growth.</p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-2">
                    <img src={da_cs_img_2} alt="" />
                </div>
            </div>
            <div className="da-cs-card-2">
                <div className="da-cs-subcard-3">
                    <div className="customer-journey-mapping">
                        <img src={customer_journey_mapping} alt="" />
                        <h3>Customer Journey Mapping</h3>
                        <p>We analyze and map out the customer journey to identify touchpoints and opportunities for enhancing the customer experience
                        </p>
                    </div>
                    <div className="marketing-automation">
                        <img src={marketing_automation} alt="" />
                        <h3>Marketing Automation</h3>
                        <p>Our consultants implement marketing automation tools to streamline your campaigns and improve efficiency
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-4">
                    <div className="personalization-strategies">
                        <img src={personalization_strategies} alt="" />
                        <h3>Personalization Strategies</h3>
                        <p>We develop strategies to create personalized experiences that engage customers and foster loyalty.
                        </p>
                    </div>
                    <div className="customer-retention">
                        <img src={customer_retention} alt="" />
                        <h3>Customer Retention</h3>
                        <p>We provide tactics to enhance customer satisfaction and retention through targeted communication and support
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-3">
                <div className="da-cs-ctn-title-4">
                    <h3>Enterprise Technology & Performance</h3>
                    <p>Optimize your IT infrastructure and improve performance with our enterprise technology consulting. We help you implement the right technologies to drive efficiency and growth. Whether you need to modernize your IT systems, improve security, or enhance performance, our consultants deliver solutions that align with your business objectives.</p>
                    <button>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-3">
                    <img src={da_cs_img_5} alt="" />
                </div>
            </div>
            <div className="da-cs-card-3">
                <div className="da-cs-subcard-5">
                    <div className="it-modernization">
                        <img src={it_modernization} alt="" />
                        <h3>IT Modernization</h3>
                        <p>We help you upgrade your IT systems to keep pace with technological advancements and improve efficiency.
                        </p>
                    </div>
                    <div className="security-enhancements">
                        <img src={security_enhancements} alt="" />
                        <h3>Security Enhancements</h3>
                        <p>Our team evaluates your security measures and implements solutions to protect your data and systems from threats.
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-6">
                    <div className="performance-optimization">
                        <img src={performance_optimization} alt="" />
                        <h3>Performance Optimization</h3>
                        <p>We assess your technology performance and recommend improvements to ensure optimal operation.
                        </p>
                    </div>
                    <div className="technology-alignment">
                        <img src={technology_alignment} alt="" />
                        <h3>Technology Alignment</h3>
                        <p>We ensure that your IT investments are aligned with your business objectives to support growth and innovation.
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-4">
                <div className="da-cs-ctn-title-4">
                    <h3>Digital Transformation</h3>
                    <p>Help your business evolve by integrating cutting-edge digital technologies into all areas of operation, fundamentally changing how you deliver value to your customers.</p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-4">
                    <img src={da_cs_img_4} alt="" />
                </div>
            </div>
            <div className="da-cs-card-4">
                <div className="da-cs-subcard-7">
                    <div className="digital-strategy-development">
                        <img src={digital_strategy_development} alt="" />
                        <h3>Digital Strategy Development</h3>
                        <p>We create a roadmap for your digital journey, helping you navigate the complexities of transitioning to digital-first operations.
                        </p>
                    </div>
                    <div className="cloud-integration">
                        <img src={cloud_integration} alt="" />
                        <h3>Cloud Integration</h3>
                        <p>Migrate your infrastructure to the cloud, enabling better scalability, flexibility, and cost-effectiveness.
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-8">
                    <div className="data-driven-insights">
                        <img src={data_driven_insights} alt="" />
                        <h3>Data-Driven Insights</h3>
                        <p>We help you leverage big data and analytics to make informed, real-time business decisions.
                        </p>
                    </div>
                    <div className="automation-solutions">
                        <img src={automation_solutions} alt="" />
                        <h3>Automation Solutions</h3>
                        <p>Implement workflow automation tools to streamline repetitive tasks, improve accuracy, and increase overall efficiency.
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-5">
                <div className="da-cs-ctn-title-5">
                    <h3>Human Capital & Workforce Management</h3>
                    <p>Empower your workforce and improve organizational performance with our human capital consulting. We help you design and implement strategies that enhance employee engagement, talent acquisition, and overall productivity.</p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-5">
                    <img src={da_cs_img_5} alt="" />
                </div>
            </div>
            <div className="da-cs-card-5">
                <div className="da-cs-subcard-9">
                    <div className="talent-acquisition">
                        <img src={talent_acquisition} alt="" />
                        <h3>Talent Acquisition</h3>
                        <p>We assist in developing recruitment strategies that attract top talent aligned with your business goals.
                        </p>
                    </div>
                    <div className="workforce-planning">
                        <img src={workforce_planning} alt="" />
                        <h3>Workforce Planning</h3>
                        <p> We help you plan your workforce needs, ensuring you have the right skills and roles to meet future demands.
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-10">
                    <div className="employee-engagement">
                        <img src={employee_engagement} alt="" />
                        <h3>Employee Engagement</h3>
                        <p>We design strategies to boost employee satisfaction, retention, and performance.
                        </p>
                    </div>
                    <div className="leadership-development">
                        <img src={leadership_development} alt="" />
                        <h3>Leadership Development</h3>
                        <p>We provide leadership training and development programs to prepare your managers and executives for future challenges.
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-6">
                <div className="da-cs-ctn-title-6">
                    <h3>Risk & Compliance Management</h3>
                    <p>Protect your business from regulatory challenges and operational risks with our comprehensive risk management and compliance consulting service</p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-6">
                    <img src={da_cs_img_7} alt="" />
                </div>
            </div>
            <div className="da-cs-card-6">
                <div className="da-cs-subcard-11">
                    <div className="regulatory-compliance">
                        <img src={regulatory_compliance} alt="" />
                        <h3>Regulatory Compliance</h3>
                        <p>Ensure your business meets all industry standards and regulatory requirements.
                        </p>
                    </div>
                    <div className="operational-risk-management">
                        <img src={operational_risk_management} alt="" />
                        <h3>Operational Risk Management</h3>
                        <p>We identify potential operational risks and create strategies to mitigate their impact.
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-12">
                    <div className="business-continuity-planning">
                        <img src={business_continuity_planning} alt="" />
                        <h3>Business Continuity Planning</h3>
                        <p>Develop disaster recovery and continuity plans to keep your operations running smoothly during unexpected disruptions.
                        </p>
                    </div>
                    <div className="cyber-risk-mitigation">
                        <img src={cyber_risk_mitigation} alt="" />
                        <h3>Cyber Risk Mitigation</h3>
                        <p>Implement cybersecurity measures to safeguard your business from digital threats.
                        </p>
                    </div>
                </div>
            </div>
            <div className="da-cs-ctn-7">
                <div className="da-cs-ctn-title-13">
                    <h3>IT Governance & Strategy</h3>
                    <p>Ensure that your IT investments deliver maximum value by aligning them with your long-term business objectives.</p>
                    <button onClick={openModal}>Learn More</button>
                </div>
                <div className="da-cs-ctn-img-7">
                    <img src={da_cs_img_8} alt="" />
                </div>
            </div>
            <div className="da-cs-card-7">
                <div className="da-cs-subcard-13">
                    <div className="it-strategy-development">
                        <img src={it_strategy_development} alt="" />
                        <h3>IT Strategy Development</h3>
                        <p>We help you create a long-term IT strategy that supports business growth and innovation.
                        </p>
                    </div>
                    <div className="governance-compliance">
                        <img src={governance_compliance} alt="" />
                        <h3>Governance & Compliance</h3>
                        <p>Ensure your IT practices comply with industry regulations and best practices.
                        </p>
                    </div>
                </div>
                <div className="da-cs-subcard-14">
                    <div className="vendor-management">
                        <img src={vendor_management} alt="" />
                        <h3>Vendor Management</h3>
                        <p> We assist in selecting and managing IT vendors to ensure you get the best services at optimal costs.
                        </p>
                    </div>
                    <div className="it-performance-monitoring">
                        <img src={it_performance_monitoring} alt="" />
                        <h3>IT Performance Monitoring</h3>
                        <p>Continuously track IT performance to ensure that it contributes to business success.
                        </p>
                    </div>
                </div>
                <div className="da-cs-ctn-8">
                    <div className="da-cs-ctn-title-14">
                        <h3>Strategy & Analytics</h3>
                        <p>In today's data-driven world, leveraging strategy and analytics is crucial for making informed business decisions. At PCL Infotech, our Strategy & Analytics consulting services are designed to help you harness the power of your data to drive strategic decisions and achieve your business objectives. Here’s how we support you</p>
                        <button onClick={openModal}>Learn More</button>
                    </div>
                    <div className="da-cs-ctn-img-8">
                        <img src={da_cs_img_3} alt="" />
                    </div>
                </div>
            </div>
            <div className="da-cs-main-1">
                <div className="da-cs-con-1">
                    <h2>Data Analysis</h2>
                    <p>Our data analysis services provide you with a deep understanding of your data, enabling you to make informed decisions. </p>
                    <img src={data_analysis_1} alt="" />
                </div>
                <div className="da-cs-con-title-1">
                    <h3>Data Collection & Integration</h3>
                    <p>We assist in gathering and integrating data from various sources, ensuring you have a comprehensive view of your business</p>
                    <h3>Advanced Analytics Techniques</h3>
                    <p>Utilizing advanced analytics techniques such as statistical analysis, predictive modeling, and machine learning, we help you uncover hidden insights and trends.
                    </p>
                    <h3>Customized Reporting</h3>
                    <p>We create customized reports and dashboards that present data in a clear, actionable format, making it easier for you to interpret and act on the information</p>
                </div>
            </div>
            <div className="da-cs-main-1">
                <div className="da-cs-con-1">
                    <h2>Trend Identification</h2>
                    <p>Understanding market and industry trends is essential for staying competitive. Our trend identification services include</p>
                    <img src={trend} alt="" />
                </div>
                <div className="da-cs-con-title-1">
                    <h3>Market Research</h3>
                    <p>We conduct thorough market research to identify emerging trends, customer behaviours, and competitive dynamics</p>
                    <h3>Industry Benchmarking</h3>
                    <p>We compare your performance against industry benchmarks to highlight areas of strength and opportunities for improvement</p>
                    <h3>Competitive Analysis</h3>
                    <p>We create customized reports and dashboards that present data in a clear, actionable format, making it easier for you to interpret and act on the information</p>
                </div>
            </div>
            <div className="da-cs-main-1">
                <div className="da-cs-con-1">
                    <h2>Strategy Development</h2>
                    <p>Developing data-driven strategies is key to aligning your business goals with actionable plans. Our strategy development process involves</p>
                    <img src={strategy_development} alt="" />
                </div>
                <div className="da-cs-con-title-1">
                    <h3>Strategic Planning Workshops</h3>
                    <p>We facilitate workshops to align your team on strategic objectives and priorities based on data insights</p>
                    <h3>Scenario Planning</h3>
                    <p>We use data to model different business scenarios, helping you evaluate potential outcomes and make informed decisions</p>
                    <h3>Goal Setting</h3>
                    <p>We work with you to set clear, measurable goals that are aligned with your strategic vision and supported by data insights</p>
                </div>
            </div>
            <div className="da-cs-main-1">
                <div className="da-cs-con-1">
                    <h2>Actionable Insights</h2>
                    <p>Transforming data into actionable insights is our core focus.</p>
                    <img src={actionable_insight} alt="" />
                </div>
                <div className="da-cs-con-title-1">
                    <h3>Insight Generation</h3>
                    <p>We analyze data to generate insights that drive business decisions, from operational improvements to strategic investments</p>
                    <h3>Decision Support</h3>
                    <p>Our insights provide support for key decisions, such as market entry, product development, and customer acquisition</p>
                    <h3>Performance Monitoring</h3>
                    <p>We help you set up systems to continuously monitor performance metrics, ensuring you can adapt strategies in response to changing conditions.</p>
                </div>
            </div>
            <div className="da-cs-main-1">
                <div className="da-cs-con-1">
                    <h2>Continuous Improvement</h2>
                    <p>Our services don’t stop at providing insights. We also focus on                </p>
                    <img src={continuous_improvement} alt="" />
                </div>
                <div className="da-cs-con-title-1">
                    <h3>Feedback Loops</h3>
                    <p>We establish feedback mechanisms to refine strategies and improve decision-making processes over time.</p>
                    <h3>Ongoing Support</h3>
                    <p>We offer ongoing support and consultation to help you adjust strategies as new data and trends emerge</p>
                    <h3>Training & Empowerment</h3>
                    <p>We provide training to your team on data analysis tools and techniques, empowering them to leverage data effectively                </p>
                </div>
            </div>
            <div className='footer'>
                <div className="footer-container">
                    <div className="footer-logo-section">
                        <div className="footer-logo">
                            <Link to="/" onClick={() => handleMenuItemClick("Home")}>
                                <img src={logo} alt="Logo" />
                            </Link>
                            <p>IT Solution</p>
                        </div>
                        <p>INFOTECH PRIVATE LIMITED</p>
                        <p></p>
                        <div className="footer-social-icons">
                            <a href="https://www.facebook.com/people/PCL-Infotech-Pvt-Ltd/61565409011377/">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://www.instagram.com/pclinfotech/">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://x.com/i/flow/login?redirect_after_login=%2Fpcl_infotech">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </div>
                    </div>
                    <div className="footer-links">
                        <div className="footer-column">
                            <h4>About Us</h4>
                            <ul>
                                <li><Link to="/Home">Home</Link></li>
                                <li><Link to="/contact-info">Contact Us</Link></li>
                                <li><Link to="/job-portal">Careers</Link></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Our Services</h4>
                            <ul>
                                <li><Link to="/web-development">Web Development</Link></li>
                                <li><Link to="/web-design">Web Design</Link></li>
                                <li><Link to="/search-engine-optimization-seo">Marketing</Link></li>
                                <li><Link to="/software-products">Software Products</Link></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Other Services</h4>
                            <ul>
                                <li><Link to="/domain-registration">Domain Registration</Link></li>
                                <li><Link to="/vps-hosting">VPS hosting</Link></li>
                                <li><Link to="/data-analytics">Management Consultant</Link></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Contact Us</h4>
                            <ul>
                                <li><i className="fas fa-envelope"></i> info@pclinfotech.com</li>
                                <li><i className="fas fa-phone"></i> +91 72000 - 74253</li>
                                <li><i className="fas fa-map-marker-alt"></i> No.2/ 156, 1st Floor, Poonamalle-Avadi Road,<br />
                                    Senneerkuppam, Chennai-56</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 PCL Infotech. All rights reserved. <Link to="/terms-and-conditions">Terms & Conditions</Link> · <Link to="/privacy-policy">Privacy Policy</Link></p>
                </div>
            </div>
        </div>
    )
}

export default DataAnalytics
