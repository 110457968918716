import React from 'react'
import './CSS/TermsConditions.css'

const TermsConditions = () => {
    return (
        <div className='t&c'>
            <div className="t-c-head-title-1">
                <h2>Terms and Conditions</h2>
            </div>
            <div className="t-c-sub-title-1">
                <h4>Attention:</h4>
                <p>This legal notice governs the entire contents of the website located at www.pclinfotech.com
                    and any correspondence by email between you and PCL Infotech. Please read these terms
                    carefully before using the Website. By using the Website, you indicate your acceptance of
                    these terms, regardless of whether or not you choose to register with us. If you do not accept
                    these terms, please do not use the Website.</p>
            </div>
            <div className="t-c-sub-title-2">
                <h4>1. Introduction</h4>
                <p>By accessing any part of the Website, you are deemed to have accepted this legal notice in
                    full. If you do not accept this legal notice in full, you must exit the Website immediately.
                    PCL Infotech reserves the right to revise this legal notice at any time by updating this
                    posting. You should periodically review the Website to remain informed about the current
                    legal notice, as it is binding upon you.</p>
            </div>
            <div className="t-c-sub-title-3">
                <h4>2. Service Access</h4>
                <p>While PCL Infotech strives to ensure that the Website is available 24 hours a day, we shall
                    not be liable if, for any reason, the Website is unavailable at any time or for any period.
                    Access to the Website may be temporarily suspended without notice due to system failures,
                    maintenance, repairs, or causes beyond our control.</p>
            </div>
            <div className="t-c-sub-title-4">
                <h4>3. Sign-Up</h4>
                <p>Each account is for individual use only. Sharing your username and password with others or
                    using multiple users on a network is prohibited. You are responsible for maintaining the
                    confidentiality of your password and account information.</p>
            </div>
            <div className="t-c-sub-title-5">
                <h4>4. Warranty</h4>
                <p>PCL Infotech provides no warranty or guarantee regarding the accuracy of the information
                    provided on the Website. We strive to deliver precise and reliable information however, we
                    cannot be held liable for any errors or omissions, or for any results arising from the use of
                    this information. Users are encouraged to exercise their discretion and judgment.</p>
            </div>
            <div className="t-c-sub-title-6">
                <h4>5. Payments</h4>
                <p>1. Monthly fees apply based on the plan you choose. Payments are due monthly for the
                    services provided.</p>
                <p>2. Refunds are not offered after payment has been made, except in cases where you are
                    unsatisfied with the work performed, in which case you will not be charged for the
                    hours used.</p>
            </div>
            <div className="t-c-sub-title-7">
                <h4>6. Web Development Terms:</h4>
                <p>1. Monthly schedules will be provided by the 10th of each month.</p>
                <p>2. Man-hour credits will be updated in real time on our reporting panel.</p>
                <p>3. Communication regarding development will occur exclusively through our reporting
                    panel.</p>
                <p>4. Minimum commitment of 6 months is required.</p>
                <p>5. Payments are due by the 3rd of each month and must be made one month in advance.</p>
                <p>6. Clients are responsible for providing text, images, and video content.</p>
                <p>7. Unused man-hour credits may be carried forward to the following month.</p>
                <p>8. Hosting costs are not included in service charges.</p>

            </div>
            <div className="t-c-sub-title-8">
                <h4>7. Digital Marketing Terms:</h4>
                <p>1. 18 man-hours are included per month.</p>
                <p>2. Monthly schedules and man-hour credit details will be communicated as outlined
                    above.</p>
                <p>3. Minimum commitment of 6 months is required.</p>
                <p>4. Payments are due by the 3rd of each month and must be made one month in advance
                    upon order confirmation.</p>
                <p>5. Clients must provide necessary text, images, and video content.</p>
                <p>6. Unused man-hour credits can be carried forward.</p>
                <p>7. Hosting costs are not included in service charges.</p>
            </div>
            <div className="t-c-sub-title-9">
                <h4>8. SEO Service Terms:</h4>
                <p>1. A confirmation order is required for a one-year duration.</p>
                <p>2. Additional charges apply for website dynamic features.</p>
                <p>3. Payments are due monthly in advance, along with the order confirmation.</p>
                <p>4. Required text content must be provided by the client.</p>
            </div>
            <div className="t-c-sub-title-10">
                <h4>9. SEO Guarantees:</h4>
                <p>1. We will submit your website to major search engines and directories.</p>
                <p>2. We do not employ unethical SEO techniques.</p>
                <p>3. We commit to transparency regarding our SEO practices and will not make changes to your website without your prior approval.</p>
                <p>4. We do not guarantee specific rankings, as search engine results are based on their
                    algorithms.</p>
            </div>
            <div className="t-c-sub-title-11">
                <h4>10. Cancellation</h4>
                <p>You may cancel your services at any time without incurring a penalty fee. To cancel an order
                    and request a refund, you must email us within 24 hours of placing your order. After 24
                    hours, if work has commenced, we will be unable to refund any advance payment.</p>
            </div>
            <div className="t-c-sub-title-12">
                <h4>11. Termination of Service</h4>
                <p>PCL Infotech reserves the right to terminate services for any client at any time for any reason,
                    including but not limited to:</p>
                <p>1. Ongoing requests for tasks beyond the scope of the service plan without an agreed
                    solution.</p>
                <p>2. Abusive or rude behavior toward any employee of PCL Infotech.</p>
            </div>
            <div className="t-c-sub-title-13">
                <h4>12. Copyright</h4>
                <p>Copyright protects authors and owners of original artistic and intellectual works. If you
                    request that our team source content or images for your website, you do so at your own risk.
                    You are responsible for ensuring all necessary permissions are obtained. PCL Infotech is not
                    liable for any copyright violations that may occur, and you may face legal sanctions if proper
                    permissions are not secured.</p>
            </div>
        </div>
    )
}

export default TermsConditions
