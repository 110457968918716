import React, { useState } from 'react'
import './CSS/SearchEngineMarketing.css'
import dtg_sem_img_2 from './Assets/dtg_sem_img_2.png'
import dtg_sem_img_3 from './Assets/dtg_sem_img_3.png'
import dtg_sem_img_4 from './Assets/dtg_sem_img_4.png'
import targeted_traffic from './Assets/targeted_traffic.png'
import custom_ppc_campaigns from './Assets/custom_ppc_campaigns.png'
import data_driven_optimization from './Assets/data_driven_optimization.png'
import comprehensive_reporting from './Assets/comprehensive_reporting.png'
import landing_page_optimization from './Assets/landing_page_optimization.png'
import continuous_support from './Assets/continuous_support.png'
import ad_budget_management from './Assets/ad_budget_management.png'
import google_ads_logo from './Assets/google_ads_logo.png'
import dtg_sem_img_5 from './Assets/dtg_sem_img_5.png'
import dtg_sem_img_6 from './Assets/dtg_sem_img_6.png'
import dtg_sem_img_7 from './Assets/dtg_sem_img_7.png'
import dtg_sem_img_8 from './Assets/dtg_sem_img_8.png'
import dtg_sem_img_9 from './Assets/dtg_sem_img_9.png'
import dtg_sem_img_10 from './Assets/dtg_sem_img_10.png'
import targeted_campaigns from './Assets/targeted_campaigns.png'
import creative_excellence from './Assets/creative_excellence.png'
import data_driven_strategy from './Assets/data_driven_strategy.png'
import platform_expertise from './Assets/platform_expertise.png'
import meta_logo from './Assets/meta_logo.png'
import x_logo from './Assets/x_logo.png'
import logo from './Assets/logo.png'
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'





const SearchEngineMarketing = () => {



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleMenuItemClick = (menuItem) => {
    // You can add any additional logic here if needed
  };


  return (
    <div className='dtg-sem-1'>
      <div className="dtg-sem-main-title">
        <h2>Advertising</h2>
        <p>Advertising is the art of capturing attention and inspiring action. By blending creativity with strategic messaging, it connects products with people’s desires and needs. Effective ads don’t just promote—they persuade and engage.</p>
      </div>
      <div className="dtg-sem-title-1">
        <h2>Search Engine Marketing (SEM) Services</h2>
      </div>
      <div className="dtg-sem-img-1">
        <img src={dtg_sem_img_2} alt="" />
        <p>Looking to boost your online visibility and drive targeted traffic quickly? Our Search Engine Marketing (SEM) services at PCL Infotech are designed to help your business achieve just that. </p>
        <img src={dtg_sem_img_3} alt="" />
      </div>
      <div className="dtg-sem-title-2">
        <button onClick={openModal}>Build my Search Engine Marketing (SEM) Services
        </button>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
        <div className="web-contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
              <label>Last Name</label>
              <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
              <label>Phone Number</label>
              <input type="text" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea name="message" placeholder="Write your message.." value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Send Message</button>
            {isSubmitted && <p>Thank you! Your form has been submitted.</p>}
          </form>
        </div>
      </Modal>
      <div className="dtg-sem-title-3">
        <p>We specialize in creating, managing, and optimizing effective Pay-Per-Click (PPC) campaigns that deliver measurable results aligned with your business goals. Whether you’re aiming to generate leads, increase sales, or enhance brand awareness, our SEM strategies ensure you get the maximum return on investment (ROI).
        </p>
      </div>
      <div className="dtg-sem-sub-title-1">
        <h2>Transform Your Online Business with PCL Infotech’ s  Search Engine Marketing (SEM) Services </h2>
        <p>At PCL Infotech, we’re committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.</p>
      </div>
      <div className="dtg-sem-img-4">
        <img src={dtg_sem_img_4} alt="" />
        <h2>Why Choose PCL Infotech for Your Search Engine Marketing (SEM) Services Needs</h2>
      </div>
      <div className="dtg-sem-2">
        <div className="dtg-sem-card-1">
          <div className="targeted-traffic">
            <img src={targeted_traffic} alt="" />
            <h3>Targeted Traffic</h3>
            <p>We focus on attracting the right audience to your website. By targeting specific
              keywords and demographics, we ensure that your ads reach potential customers who
              are most likely to convert, driving high-quality traffic that aligns with your business
              objectives.</p>
          </div>
          <div className="custom-ppc-campaigns">
            <img src={custom_ppc_campaigns} alt="" />
            <h3>Custom PPC Campaigns</h3>
            <p>Transparency is central to our method. We offer comprehensive and
              straightforward reports that clearly illustrate the performance of your campaigns. You’ll
              have full visibility into metrics like impressions, clicks, conversions, and ROI, so you can
              see the tangible impact of our efforts.</p>
          </div>
          <div className="data-driven-optimization">
            <img src={data_driven_optimization} alt="" />
            <h3>Data-Driven Optimization</h3>
            <p>We are convinced of the value that data brings. Our SEM experts continuously
              monitor and analyze your campaign’s performance, using real-time data to make
              informed adjustments that improve click-through rates (CTR), reduce cost-per-click
              (CPC), and increase conversions. This includes leveraging Google advertising tools to
              refine your strategy.</p>
          </div>
        </div>
        <div className="dtg-sem-card-2">
          <div className="comprehensive-reporting">
            <img src={comprehensive_reporting} alt="" />
            <h3>Comprehensive Reporting</h3>
            <p>Transparency is central to our method. We offer comprehensive and
              straightforward reports that clearly illustrate the performance of your campaigns. You’ll
              have full visibility into metrics like impressions, clicks, conversions, and ROI, so you can
              see the tangible impact of our efforts.</p>
          </div>
          <div className="landing-page-optimization">
            <img src={landing_page_optimization} alt="" />
            <h3>Landing Page Optimization</h3>
            <p>A successful SEM campaign doesn’t stop at the ad click. We optimize your landing
              pages to ensure they are fully aligned with your ads and designed to convert visitors into
              customers. This holistic approach enhances the effectiveness of your campaigns and
              improves overall results.</p>
          </div>
          <div className="continuous-support">
            <img src={continuous_support} alt="" />
            <h3>Continuous Support and Consultation</h3>
            <p>SEM is an ongoing process, and we’re here to support you every step of the way.
              Our team provides continuous consultation and support, helping you navigate the
              complexities of search engine marketing and make informed decisions that drive
              success.</p>
          </div>
        </div>
        <div className="dtg-sem-card-3">
          <div className="ad-budget-management">
            <img src={ad_budget_management} alt="" />
            <h3>Ad Budget Management</h3>
            <p>We manage your ad spending with precision, ensuring that your budget is
              allocated effectively across your campaigns. Our goal is to maximize your ROI while
              minimizing unnecessary expenses, helping you achieve the best possible results within
              your budget.</p>
          </div>
        </div>
        <div className="dtg-sem-button-1">
          <button onClick={openModal}>CONTACT US</button>
        </div>
      </div>
      <div className="dtg-sem-title-6">
        <h2>Empower Your Business with a Next-Generation Search Engine Marketing </h2>
        <p>At PCL Infotech, we believe that a well-crafted mobile app can be a game-changer for your business. Our mobile app development services are designed to empower your brand, enhance customer engagement, and support your overall business strategy. Let us help you create an app that not only meets your needs but exceeds your.</p>
      </div>
      <div className="dtg-sem-main-title-6">
        <h2>Social Media Ads</h2>
      </div>
      <div className="dtg-sem-img-5">
        <img src={dtg_sem_img_5} alt="" />
        <p>Social Media Ads for IT Solutions – Boost Your Presence on Facebook, Instagram, Twitter, and LinkedIn</p>
        <img src={dtg_sem_img_6} alt="" />
      </div>
      <div className="dtg-sem-main-title-7">
        <button>Build my Social Media Ads Services
        </button>
        <p>In today’s digital landscape, promoting your IT solutions business requires a strategic approach to social media advertising. At PCL Infotech, we help you harness the power of platforms like Facebook, Instagram, Twitter, and LinkedIn to reach a diverse audience, generate leads, and boost your online presence.
        </p>
      </div>
      <div className="dtg-sem-ctn-title">
        <h2>Our Social Media Ads Services Includes</h2>
      </div>
      <div className="dtg-sem-ctn">
        <div className="dtg-sem-ctn-1">
          <h3>Facebook Ads – Precision Targeting for IT Services</h3>
          <p>Facebook provides advanced targeting options based on user interests, behaviors, and demographics. such as cloud computing, cybersecurity, and software development. Our campaigns leverage Facebook’s lead generation ads to collect valuable contact details of potential clients. </p>
        </div>
        <div className="dtg-sem-ctn-img-1">
          <img src={dtg_sem_img_7} alt="" />
        </div>
        <div className="dtg-sem-ctn-2">
          <h3>Instagram Ads – Visually Captivating Content
          </h3>
          <p>Instagram’s visual-centric platform is ideal for creatively showcasing your IT solutions. With Instagram Stories ads, we create time-sensitive promotions that catch attention instantly, driving quick engagement with your audience.
          </p>
        </div>
        <div className="dtg-sem-ctn-img-2">
          <img src={dtg_sem_img_8} alt="" />
        </div>
        <div className="dtg-sem-ctn-3">
          <h3>X  Ads – Real-Time Engagement for IT Promotions</h3>
          <p>X is perfect for promoting time-sensitive offers, product launches, or tech events. We create compelling sponsored tweets and Twitter Cards that share impactful messages about your IT services. By incorporating trending hashtags, we increase the visibility of your content among tech enthusiasts and potential clients,
          </p>
        </div>
        <div className="dtg-sem-ctn-img-3">
          <img src={dtg_sem_img_9} alt="" />
        </div>
        <div className="dtg-sem-ctn-4">
          <h3>LinkedIn Ads – B2B Targeting for IT Professionals</h3>
          <p>As the leading platform for B2B marketing, LinkedIn offers direct access to professionals and decision-makers in the tech industry. We create Sponsored Content and In Mail campaigns designed to reach companies in need of IT solutions, from cloud services to enterprise software. Our approach includes sharing educational content such as case studies, whitepapers, and industry insights, building trust, and positioning your brand as a thought leader.
          </p>
        </div>
        <div className="dtg-sem-ctn-img-4">
          <img src={dtg_sem_img_10} alt="" />
        </div>
      </div>
      <div className="dtg-sem-3">
        <div className="dtg-sem-subcard">
          <h2>Why Our Web Design Services Excel</h2>
        </div>
        <div className="dtg-sem-subcard-1">
          <div className="targeted-campaigns">
            <img src={targeted_campaigns} alt="" />
            <h3>Targeted Campaigns</h3>
            <p>We use advanced targeting features across all platforms to ensure your ads reach the most relevant audience for your IT services.
            </p>
          </div>
          <div className="creative-excellence">
            <img src={creative_excellence} alt="" />
            <h3>Creative Excellence</h3>
            <p>Our team excels at creating visually engaging and informative ads that resonate with your target market.
            </p>
          </div>
          <div className="data-driven-strategy">
            <img src={data_driven_strategy} alt="" />
            <h3>Data-Driven Strategy</h3>
            <p>Through constant monitoring and real-time analytics, we refine your campaigns to maximize reach, engagement, and conversions.
            </p>
          </div>
        </div>
        <div className="dtg-sem-subcard-2">
          <div className="platform-expertise">
            <img src={platform_expertise} alt="" />
            <h3>Platform Expertise</h3>
            <p>We understand the nuances of each platform and tailor strategies that align with best practices for Facebook, Instagram, Twitter, and LinkedIn.
            </p>
          </div>
        </div>
        <div className="dtg-sem-button-2">
          <button onClick={openModal}>CONTACT US</button>
        </div>
      </div>
      <div className="dtg-sem-3">
        <div className="dtg-sem-title-7">
          <h2>Elevate Your Brand with PCL Infotech’s Search Engine Marketing
            (SEM) Services  &  Social Media Ads </h2>
          <p>Social media is more than just a platform for communication it’s a powerful tool for growth. At PCL Infotech, we help you harness the full potential of social media to build a strong, engaged community around your brand. Let us help you turn followers into loyal customers and transform your social media presence into a key driver of your business success.
          </p>
          <div className="dtg-sem-4">
            <div className="dtg-sem-logo-container-1">
              <img src={google_ads_logo} alt="" />
              <img src={meta_logo} alt="" />
              <img src={x_logo} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className="footer-container">
          <div className="footer-logo-section">
            <div className="footer-logo">
              <Link to="/" onClick={() => handleMenuItemClick("Home")}>
                <img src={logo} alt="Logo" />
              </Link>
              <p>IT Solution</p>
            </div>
            <p>INFOTECH PRIVATE LIMITED</p>
            <p></p>
            <div className="footer-social-icons">
              <a href="https://www.facebook.com/people/PCL-Infotech-Pvt-Ltd/61565409011377/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/pclinfotech/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://x.com/i/flow/login?redirect_after_login=%2Fpcl_infotech">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <h4>About Us</h4>
              <ul>
                <li><Link to="/Home">Home</Link></li>
                <li><Link to="/contact-info">Contact Us</Link></li>
                <li><Link to="/job-portal">Careers</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>Our Services</h4>
              <ul>
                <li><Link to="/web-development">Web Development</Link></li>
                <li><Link to="/web-design">Web Design</Link></li>
                <li><Link to="/search-engine-optimization-seo">Marketing</Link></li>
                <li><Link to="/software-products">Software Products</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>Other Services</h4>
              <ul>
                <li><Link to="/domain-registration">Domain Registration</Link></li>
                <li><Link to="/vps-hosting">VPS hosting</Link></li>
                <li><Link to="/data-analytics">Management Consultant</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>Contact Us</h4>
              <ul>
                <li><i className="fas fa-envelope"></i> info@pclinfotech.com</li>
                <li><i className="fas fa-phone"></i> +91 72000 - 74253</li>
                <li><i className="fas fa-map-marker-alt"></i> No.2/ 156, 1st Floor, Poonamalle-Avadi Road,<br />
                  Senneerkuppam, Chennai-56</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 PCL Infotech. All rights reserved. <Link to="/terms-and-conditions">Terms & Conditions</Link> · <Link to="/privacy-policy">Privacy Policy</Link></p>
        </div>
      </div>
    </div>
  )
}

export default SearchEngineMarketing
