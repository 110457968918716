import React from 'react'
import './CSS/Privacypolicy.css'

const Privacypolicy = () => {
    return (
        <div className='p-p'>
            <div className="p-p-head-title-1">
                <h2>Privacy Policy</h2>
                <p>At PCL Infotech, we value your privacy and are committed to protecting your personal
                    information. This Privacy Policy outlines how we collect, use, and safeguard your data when
                    you interact with our website or services.</p>
            </div>
            <div className="p-psub-title-1">
                <h4>Collection of Information</h4>
                <p>When you submit an inquiry through our website, we may collect the following personal
                    information:</p>
                <p>Name</p>
                <p>Email address</p>
                <p>Mailing address</p>
                <p>Phone number</p>
            </div>
            <div className="p-psub-title-2">
                <h4>How Do We Use Your Information?</h4>
                <p>Personalizing Your Experience: Your data allows us to better understand your
                    needs and customize our services to provide a tailored experience.</p>
                <p>Improving Our Website: We continually work to enhance our website and services
                    based on the feedback and data we receive from users like you.</p>
                <p>Enhancing Customer Service: Your information helps us respond more effectively
                    to your customer service inquiries and support needs.</p>
                <p>Processing Transactions: Your personal information will not be shared, sold, or
                    transferred to outside parties without your consent, except when necessary to fulfill a
                    transaction or provide the service you requested.</p>
                <p>Communicating with You: The email address you provide may be used to send you
                    information about updates, promotions, or service-related notifications.</p>
            </div>
            <div className="p-psub-title-3">
                <h4>Do We Disclose Your Information to Third Parties?</h4>
                <p>We do not sell, trade, or otherwise transfer your personal information to outside parties.
                    However, we may share information with trusted third parties who assist us in operating our
                    website, conducting our business, or providing services to you. These parties must agree to
                    keep your information confidential. We may also release your information when required by
                    law, or to protect the rights, property, or safety of PCL Infotech or others. Non-personally
                    identifiable visitor information may be shared with third parties for marketing, advertising, or
                    similar uses.</p>
            </div>
            <div className="p-psub-title-4">
                <h4>Security & Data Protection</h4>
                <p>We prioritize the security of your personal information and implement advanced encryption
                    and protection measures. All personal data provided through our inquiry form, such as email addresses and phone numbers, is kept secure. Only authorized personnel with strict
                    confidentiality agreements can access this information.</p>
            </div>
            <div className="p-psub-title-5">
                <h4>Changes to Our Privacy Policy</h4>
                <p>PCL Infotech reserves the right to update this privacy policy at any time. Any changes will be
                    posted on this page to keep you informed about how we protect your information.</p>
            </div>
            <div className="p-psub-title-6">
                <h4>Contact Us</h4>
                <p>If you have any questions or concerns about this Privacy Policy, please reach out to us using
                    the contact details below:</p>
            </div>
            <div className="p-psub-title-7">
                <h4>PCL INFOTECH</h4>
                <p>No. 2/156, 1 st floor, Poonamalle- Avadi Road, Senneerkuppam, Chennai-56</p>
            </div>
        </div>
    )
}

export default Privacypolicy
