import React, {useState} from 'react'
import './CSS/OpenSourceDevelopment.css'
import web_dev_img_1 from './Assets/web_dev_img_1.png'
import web_opn_img_2 from './Assets/web_opn_img_2.png'
import web_opn_img_3 from './Assets/web_opn_img_3.png'
import web_ecom_img_4 from './Assets/web_ecom_img_4.png'
import custom_solutions from './Assets/custom_solutions.png'
import cost_effective_development from './Assets/cost_effective_development.png'
import community_driven_innovation from './Assets/community_driven_innovation.png'
import scalability_and_flexibility from './Assets/scalability_and_flexibility.png'
import security_and_stability from './Assets/security_and_stability.png'
import seamless_integrations_1 from './Assets/seamless_integrations_1.png'
import maintenance from './Assets/maintenance.png'
import html_logo from './Assets/html_logo.png'
import css_logo from './Assets/css_logo.png'
import javascript_logo from './Assets/javascript_logo.png'
import react_logo from './Assets/react_logo.png'
import angular_logo from './Assets/angular_logo.png'
import next_logo from './Assets/next_logo.png'
import express_logo from './Assets/express_logo.png'
import nodejs_logo from './Assets/nodejs_logo.png'
import mongo_logo from './Assets/mongo_logo.png'
import php_logo from './Assets/php_logo.png'
import mysql_logo from './Assets/mysql_logo.png'
import postsql_logo from './Assets/postsql_logo.png'
import logo from './Assets/logo.png'
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'




const OpenSourceDevelopment = () => {




  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  const handleMenuItemClick = (menuItem) => {
    // You can add any additional logic here if needed
};


  return (
    <div className='web-opn'>
      <div className="web-opn-1">
        <div className="web-opn-left">
          <h2>Open Source Development
          </h2>
          <p>Open-source software platforms offer a flexible, scalable, and cost-effective foundation for
            building customized digital solutions. At PCL Infotech, we leverage the power of open-source
            development to deliver robust and innovative websites, applications, and e-commerce
            platforms. We create tailored solutions that meet your unique business needs while keeping
            development costs manageable.</p>
          <button onClick={openModal}>Build my Open source development </button>
        </div>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
        <div className="web-contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
              <label>Last Name</label>
              <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
              <label>Phone Number</label>
              <input type="text" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea name="message" placeholder="Write your message.." value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Send Message</button>
            {isSubmitted && <p>Thank you! Your form has been submitted.</p>}
          </form>
        </div>
      </Modal>
        <div className="web-opn-right">
          <img src={web_dev_img_1} alt="" />
          <img src={web_opn_img_2} alt="" />
        </div>
      </div>
      <div className="web-opn-2">
        <div className="web-opn-2-left">
          <img src={web_opn_img_3} alt="" />
          <p>Our commitment to open source tools and frameworks not only provides you with a foundation that adapts to your evolving needs but also supports a collaborative development process that drives efficiency and innovation. This version highlights the benefits of flexibility, scalability, and cost-effectiveness, while also mentioning the collaborative and adaptive nature of open source solutions.</p>
        </div>
        <div className="web-opn-2-right">
          <img src={web_ecom_img_4} alt="" />
        </div>
      </div>
      <div className="web-opn-3">
        <div className="web-opn-title-1">
          <h2>Transform Your Online Business with PCL Infotech’ s Open Source  Development </h2>
          <p>At PCL Infotech, we’re committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.</p>
          <h2>Why Choose PCL Infotech for Your Open Source development Needs</h2>
        </div>
        <div className="web-opn-card-1">
          <div className="custom-solutions">
            <img src={custom_solutions} alt="" />
            <h3>Custom Solutions</h3>
            <p>We don’t just implement open-source software platforms; we customize and
              extend them to fit your specific requirements. Whether you need a custom theme,
              plugin, module, or integration, we deliver solutions that align perfectly with your
              business objectives.</p>
          </div>
          <div className="cost-effective-development">
            <img src={cost_effective_development} alt="" />
            <h3>Cost-Effective Development</h3>
            <p>Open-source software platforms provide a cost-efficient way to build and maintain
              digital solutions. We help you maximize this advantage by delivering high-quality,
              feature-rich solutions without the hefty price tag associated with proprietary software.</p>
          </div>
          <div className="community-driven-innovation">
            <img src={community_driven_innovation} alt="" />
            <h3>Community-Driven Innovation</h3>
            <p>Open-source software platforms benefit from large, active communities that
              constantly contribute to their improvement. We stay on top of the latest updates and
              innovations, ensuring your solution is always up-to-date, secure, and aligned with
              industry best practices.</p>
          </div>
        </div>
        <div className="web-opn-card-2">
          <div className="scalability-and-flexibility">
            <img src={scalability_and_flexibility} alt="" />
            <h3>Scalability and Flexibility</h3>
            <p>As your business evolves, your digital solutions should seamlessly expand to
              match. Our open-source development services are designed with scalability in mind,
              allowing you to easily add new features, expand functionalities, and adapt to changing
              market conditions.</p>
          </div>
          <div className="security-and-stability">
            <img src={security_and_stability} alt="" />
            <h3>Security and Stability</h3>
            <p>We prioritize security in every project, implementing best practices and
              leveraging the security features of open-source software platforms. Regular updates,
              patches, and monitoring are part of our service to keep your site secure and stable.</p>
          </div>
          <div className="seamless-integrations-1">
            <img src={seamless_integrations_1} alt="" />
            <h3>Seamless Integrations</h3>
            <p>Our expertise extends to integrating open-source software platforms with third-
              party tools and services, such as CRM systems, payment gateways, and analytics tools.
              This ensures your digital solution works seamlessly with the rest of your business
              infrastructure.</p>
          </div>
        </div>
        <div className="web-opn-card-3">
          <div className="maintenance">
            <img src={maintenance} alt="" />
            <h3>Ongoing Support and Maintenance</h3>
            <p>We offer continuous support and maintenance to keep your open-source
              software solution running smoothly. From troubleshooting and updates to performance
              optimization, we’re here to ensure your digital platform remains a valuable asset to
              your business.</p>
          </div>
        </div>
        <div className="web-opn-button">
          <button onClick={openModal}>CONTACT US</button>
        </div>
      </div>
      <div className="web-opn-4">
        <div className="web-opn-title-2">
          <h2>Elevate Your Brand with PCL Infotech’s e -Commerce web development Services
          </h2>
          <p>At PCL Infotech, we believe that great design is about more than just looks—it’s about creating a meaningful connection with your audience. Let us help you build a website that not only stands out from the competition but also drives engagement and growth. Partner with us to transform your digital presence and leave a lasting impression on every visitor.</p>
          <div className="web-opn-5">
            <div className="web-opn-logo-container-1">
              <img src={html_logo} alt="" />
              <img src={css_logo} alt="" />
              <img src={javascript_logo} alt="" />
              <img src={react_logo} alt="" />
            </div>
            <div className="web-opn-logo-container-2">
              <img src={angular_logo} alt="" />
              <img src={next_logo} alt="" />
              <img src={express_logo} alt="" />
              <img src={nodejs_logo} alt="" />
            </div>
            <div className="web-opn-logo-container-3">
              <img src={mongo_logo} alt="" />
              <img src={php_logo} alt="" />
              <img src={mysql_logo} alt="" />
              <img src={postsql_logo} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
            <div className="footer-container">
                <div className="footer-logo-section">
                    <div className="footer-logo">
                        <Link to="/" onClick={() => handleMenuItemClick("Home")}>
                            <img src={logo} alt="Logo" />
                        </Link>
                        <p>IT Solution</p>
                    </div>
                    <p>INFOTECH PRIVATE LIMITED</p>
                    <p></p>
                    <div className="footer-social-icons">
                        <a href="https://www.facebook.com/people/PCL-Infotech-Pvt-Ltd/61565409011377/">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://www.instagram.com/pclinfotech/">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://x.com/i/flow/login?redirect_after_login=%2Fpcl_infotech">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </div>
                </div>
                <div className="footer-links">
                    <div className="footer-column">
                        <h4>About Us</h4>
                        <ul>
                            <li><Link to="/Home">Home</Link></li>
                            <li><Link to="/contact-info">Contact Us</Link></li>
                            <li><Link to="/job-portal">Careers</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Our Services</h4>
                        <ul>
                            <li><Link to="/web-development">Web Development</Link></li>
                            <li><Link to="/web-design">Web Design</Link></li>
                            <li><Link to="/search-engine-optimization-seo">Marketing</Link></li>
                            <li><Link to="/software-products">Software Products</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Other Services</h4>
                        <ul>
                            <li><Link to="/domain-registration">Domain Registration</Link></li>
                            <li><Link to="/vps-hosting">VPS hosting</Link></li>
                            <li><Link to="/data-analytics">Management Consultant</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><i className="fas fa-envelope"></i> info@pclinfotech.com</li>
                            <li><i className="fas fa-phone"></i> +91 72000 - 74253</li>
                            <li><i className="fas fa-map-marker-alt"></i> No.2/ 156, 1st Floor, Poonamalle-Avadi Road,<br />
                            Senneerkuppam, Chennai-56</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 PCL Infotech. All rights reserved. <Link to="/terms-and-conditions">Terms & Conditions</Link> · <Link to="/privacy-policy">Privacy Policy</Link></p>
            </div>
        </div>
    </div>
  )
}

export default OpenSourceDevelopment